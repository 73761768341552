.blush-loading {
    width: 310px;
    height: 400px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-color: #b27b8c;
    background-image: url('../../assets/blush_pattern.svg');
    background-size: contain;
    background-position: center center;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
    }
}

.inpage-310400 {
    width: 310px;
    height: 400px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-color: #b27b8c;
    background-image: url('../../assets/blush_pattern.svg');
    background-size: contain;
    background-position: center center;

    &:before {
        content: '';
        width: 600px;
        top: 200px;
        height: 600px;
        left: -145px;

        background-color: #eac7d0;
        border-radius: 100%;
        position: absolute;
        z-index: 0;
    }

    .logo {
        position: absolute;
        transform: rotate(-90deg);
        top: 10px;
        right: -35px;
        width: 140px;
        height: 140px;
    }

    .upper {
        width: 100%;
        // background-color: #f6cbd4;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: -50px;

        p {
            margin: 15px 0 -5px 0;
            padding: 0;
            font-weight: 900;
            width: 90%;
            text-align: left;
            color: white;
        }

        h1 {
            color: white !important;
            margin: 0;
            padding: 0;
            color: #222222;
            width: 90%;
            text-align: left;
            font-size: 38px;
        }
    }

    .alice-carousel {
    }
    .product {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 250px;
        position: relative;

        img {
            max-height: 80%;
            object-fit: contain;
            max-width: 70%;
        }

        .label {
            color: black;
            position: absolute;
            bottom: 20px;
            left: 25px;
            color: white;

            p {
                margin: 0;
                font-size: 14px;
            }
            b {
                width: 170px;
                display: inline-block;
            }
        }

        .price {
            background-color: none;
            background-color: white;
            border-radius: 5px;
            color: #c74b67;
            position: absolute;
            bottom: 20px;
            right: 25px;
            padding: 4.5px 14.5px;
            font-size: 14px;

            .priceBefore {
                color: rgba(black, 0.3);
                text-decoration: line-through;
                margin-left: 10px;
            }
        }
    }
}
