.blush-loading {
    width: 1018px;
    height: 360px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-color: #b27b8c;
    background-image: url('../../assets/blush_pattern.svg');
    background-size: contain;
    background-position: center center;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
    }
}

.inpage-1018360 {
    width: 1018px;
    height: 360px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-color: #b27b8c;
    background-image: url('../../assets/blush_pattern.svg');
    background-size: contain;
    background-position: center center;

    &:before {
        content: '';

        $height: 1200px;
        width: $height;
        height: $height;

        right: -600px;
        top: -600px;

        background-color: #eac7d0;
        border-radius: 100%;
        position: absolute;
        z-index: 0;
    }

    .logo {
        position: absolute;
        transform: rotate(-90deg);
        top: 10px;
        right: -35px;
        width: 140px;
        height: 140px;
    }

    .upper {
        width: 100%;
        // background-color: #f6cbd4;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: -50px;

        p {
            margin: 15px 0 -5px 0;
            padding: 0;
            font-weight: 900;
            width: 90%;
            text-align: left;
            color: white;
        }

        h1 {
            color: white !important;
            margin: 0;
            padding: 0;
            color: #222222;
            width: 90%;
            text-align: left;
            font-size: 38px;
        }
    }

    .alice-carousel {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
    }
    .product {
        cursor: pointer;
        width: 100%;
        height: 360px;
        display: flex;
        justify-content: center;
        // height: 250px;
        position: relative;

        img {
            margin-top: 40px;
            height: 1000px;
            max-height: 280px;
            object-fit: contain;
            max-width: 280px;
        }

        .label {
            color: black;
            position: absolute;
            bottom: 20px;
            left: 25px;
            color: white;

            p {
                margin: 0;
                font-size: 14px;
            }
            b {
                width: 170px;
                display: inline-block;
            }
        }

        .price {
            background-color: none;
            // border: 1px solid #dddd;
            background-color: white;
            border-radius: 5px;
            color: #c74b67;
            position: absolute;
            bottom: 20px;
            right: 25px;
            padding: 4.5px 14.5px;

            .priceBefore {
                color: rgba(black, 0.3);
                text-decoration: line-through;
                margin-left: 10px;
            }
        }
    }
}
