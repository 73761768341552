.sog-310400 {
    width: 310px;
    height: 400px;
    overflow: hidden;
    border-radius: 8px;
    background-color: #f8f8f5;

    .upper {
        width: 100%;
        background-color: #f6cbd4;
        height: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: -50px;

        .logo {
            margin: 15px 0;
            width: 45px;
        }

        p {
            margin: 15px 0 -10px 0;
            padding: 0;
            font-weight: 900;
            width: 90%;
            text-align: left;
        }

        h1 {
            color: white !important;
            margin: 0;
            padding: 0;
            color: #222222;
            width: 90%;
            text-align: left;
            font-size: 48px;
        }
    }

    .product {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 250px;
        position: relative;

        img {
            max-height: 80%;
            object-fit: contain;
            max-width: 70%;
        }

        .label {
            color: black;
            position: absolute;
            bottom: 20px;
            left: 25px;

            p {
                margin: 0;
                font-size: 14px;
            }
            b {
                color: #e06f88;
                width: 130px;
                display: inline-block;
            }
        }

        .price {
            background-color: none;
            border: 1px solid #dddd;
            color: black;
            position: absolute;
            bottom: 20px;
            right: 25px;
            padding: 4.5px 14.5px;
        }
    }
}
